<template>
  <div class="column form-submit btn-next">
    <b-button
      :disabled="!nextable"
      :loading="loading"
      class="mr-2"
      native-type="submit"
    >
      <b-icon :icon="'send'" type="is-primary"></b-icon>
    </b-button>
  </div>
</template>
<script>
export default {
  name: "MainNav",
  props: {
    loading: {
      type: Boolean,
    },
    backable: {
      type: Boolean,
    },
    nextable: {
      type: Boolean,
    },
    hideBackBtn: {
      type: Boolean,
    },
    current: {
      type: Number,
      default: 0,
    },
    nextText: {
      type: String,
      default: "Next",
    },
    max: {
      type: Number,
      default: 100,
    },
  },
};
</script>
<style scoped>
.btn-next {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>

export const QuestionTypeChatComponentsMixin = {
  components: {
    ErrorMessage: () => import("@/components/chat/ErrorMessage"),
    Policy: () => import("@/components/chat/Policy"),
    Reward: () => import("@/components/chat/Reward"),
    SandboxHtml: () => import("@/components/chat/SandboxHtml"),
    QSL: () => import("@/components/chat/SelectLanguage"),
    QSA: () => import("@/components/chat/SingleAnswer"),
    QMA: () => import("@/components/chat/MultipleAnswer"),
    QFA: () => import("@/components/chat/FreeAnswer"),
    QNI: () => import("@/components/chat/NumberInput"),
    QTD: () => import("@/components/chat/TextDisplay"),
    QSAM: () => import("@/components/chat/SingleAnswerMatrix"),
    QDL: () => import("@/components/chat/DropdownList"),
    QS: () => import("@/components/chat/Satisfaction"),
    QDT: () => import("@/components/chat/DateTimePicker"),
    QMI: () => import("@/components/chat/MultipleInput"),
    QFU: () => import("@/components/chat/FileUpload"),
    QR: () => import("@/components/chat/Ranking"),
    QSO: () => import("@/components/chat/ScreenOut"),
    QLL: () => import("@/components/chat/LatLong"),
    QC: () => import("@/components/chat/Credential"),
    QQF: () => import("@/components/chat/QuotaFull"),
    QMAM: () => import("@/components/chat/MultipleAnswerMatrix"),
  },
};

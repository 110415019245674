<template>
  <div class="container survey chat-container" id="survey-scroller">
    <Policy
      :lang="lang"
      @confirmed="showPolicy = false"
      @ready="(payload) => (invisible = !payload)"
      v-if="showPolicy"
    >
    </Policy>
    <Reward
      :lang="lang"
      :rid="response._id"
      :sid="survey.sid"
      @ready="(payload) => (invisible = !payload)"
      :uuid="response.uuid"
      v-else-if="
        !forceHideReward &&
          isFinished &&
          (endType === 'defaultrewards' || showReward)
      "
    >
    </Reward>
    <template v-else-if="!error && survey">
      <template>
        <div class="container" style="padding: 0 1.5rem">
          <div class="container mobile-progress">
            <b-progress
              :max="max"
              :value="current"
              size="is-tiny"
              type="is-dark-green"
            >
            </b-progress>
          </div>
          <div class="chat-survey-name">{{ titleSurvey }}</div>
          <section class="live-chat" id="live-chat">
            <div class="chats" id="chats">
              <div class="columns is-centered is-vcentered">
                <div class="column is-12">
                  <div v-for="(chat, index) in allQuestions" :key="index">
                    <div class="question" v-if="chat.question.type !== 'QF'">
                      <div class="avatar">
                        <img
                          class="img-avatar"
                          src="https://koeeru.com/wp-content/uploads/2022/06/cropped-favicon-180x180.png"
                          alt="Avatar"
                        />
                      </div>
                      <div class="question-item">
                        <span
                          class="fade-in-text"
                          v-html="decodeHtml(chat.question.question[lang])"
                        ></span>
                      </div>
                    </div>
                    <div class="answer">
                      <div
                        class="answer-item"
                        v-if="chat.answer[chat.question.qid]"
                      >
                      <span v-if="['MI','FA','NI'].includes(chat.question.type)">{{ decodeHtml(chat.answer[chat.question.qid]) }}</span>
                      <span
                        v-else
                          v-html="decodeHtml(chat.answer[chat.question.qid])"
                        ></span>
                      </div>
                    </div>
                    <div class="question">
                      <div
                        class="avatar"
                        v-if="
                          index === allQuestions.length - 1 && isLoadingQuestion
                        "
                      >
                        <img
                          class="img-avatar"
                          src="https://koeeru.com/wp-content/uploads/2022/06/cropped-favicon-180x180.png"
                          alt="Avatar"
                        />
                      </div>
                      <div
                        class="question-item"
                        v-if="
                          index === allQuestions.length - 1 && isLoadingQuestion
                        "
                      >
                        <div class="loader"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ValidationObserver
              class="footer-form"
              ref="surveySubmitObserver"
              tag="div"
              v-slot="{ invalid }"
              v-if="!isLoadingQuestion"
            >
              <div class="space-content" v-if="!isLoadingQuestion">
                <div class="space-content-type-question">
                  {{ showInstruction(questionType) }}
                </div>
              </div>
              <form
                @keypress.enter.exact.prevent
                @submit.prevent="onSubmit"
                id="survey"
              >
                <div
                  :name="transitionType"
                  tag="div"
                  :class="{ 'is-hidden': invisible }"
                  class="columns is-centered is-vcentered chat-content-question"
                  v-if="
                    page &&
                      !['SAM', 'MAM', 'MI'].includes(
                        questions[questionIndexOfPage].type
                      )
                  "
                >
                  <component
                    v-if="!isScreenOut && !isQuotaFull"
                    :ref="`ref${questions[questionIndexOfPage].code}`"
                    :key="questions[questionIndexOfPage].qid"
                    :name="transitionType"
                    :is="`Q${questions[questionIndexOfPage].type}`"
                    :lang="lang"
                    :loading="loading"
                    :question="questions[questionIndexOfPage]"
                    :show-question-code="showQuestionCode"
                    :value="changes"
                    :should-focus="questions[questionIndexOfPage].length === 1"
                    :sid="survey.sid"
                    :respondent="response"
                    :last-action="transitionType"
                    :result="result"
                    :result-qcode="resultWithQCode"
                    @ready="(payload) => (invisible = !payload)"
                    @move-next="moveNext"
                    @submit="onSubmit"
                    @move-back="onBack"
                    @screen-out="onScreenOut"
                    @quota-full="onQuotaFull"
                    @mounted="syncWith"
                    @input="onChange"
                    @is-enter="isEnterActionSelect"
                  ></component>
                </div>
                <div
                  :name="transitionType"
                  tag="div"
                  :class="{ 'is-hidden': invisible }"
                  class="columns is-centered is-vcentered chat-content-question"
                  v-else
                >
                  <component
                    :ref="`ref${question.code}`"
                    :key="question.qid"
                    :name="transitionType"
                    v-for="question in questionsSAM"
                    :is="`Q${question.type}`"
                    :lang="lang"
                    :loading="loading"
                    :question="question"
                    :show-question-code="showQuestionCode"
                    :value="changes"
                    :should-focus="questions.length === 1"
                    :sid="survey.sid"
                    :respondent="response"
                    :last-action="transitionType"
                    :result="result"
                    :result-qcode="resultWithQCode"
                    @ready="(payload) => (invisible = !payload)"
                    @move-next="moveNext"
                    @submit="onSubmit"
                    @move-back="onBack"
                    @screen-out="onScreenOut"
                    @quota-full="onQuotaFull"
                    @mounted="syncWith"
                    @input="onChange"
                    @is-enter="isEnterActionSelect"
                  ></component>
                </div>
                <FormNav
                  v-if="!isScreenOut && !isQuotaFull && isShowQuestion"
                  :back-text="$t('btn.previous')"
                  :backable="isBackable"
                  :current="current"
                  :hide-back-btn="!survey.is_backable"
                  :loading="loading"
                  :max="max"
                  :next-text="nextText"
                  :nextable="!(current === max) || invalid"
                  :survey-level="survey.survey_chain_level"
                  :is-last-page="isLastPage && isLastPageNew"
                  :is-text-display="isTextDisplay"
                  @back="onBack"
                ></FormNav>
              </form>
            </ValidationObserver>
          </section>
        </div>
      </template>
      <section class="hero is-fullheight" v-if="isFinished">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered is-vcentered">
              <div class="column is-12">
                <div class="has-text-centered" v-if="!endType">
                  <h1 class="title">
                    {{ $t("endMessage") }}
                  </h1>
                </div>
                <SandboxHtml
                  :html="translations.end_msg"
                  v-if="endType === 'message'"
                ></SandboxHtml>
                <SandboxHtml
                  :html="$t('errors.URL_NOT_FOUND')"
                  v-if="urlError"
                ></SandboxHtml>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <ErrorMessage
      :error="error"
      @ready="(payload) => (invisible = !payload)"
    ></ErrorMessage>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import {
  BACK,
  NEXT,
  START_SURVEY,
  UPDATE_CHANGES,
  SCREEN_OUT,
  LOGIN,
  QUOTA_FULL,
  SUBMIT_PREVIEW,
} from "../store";
import { getSurveyFull } from "@/api/response";
import FormNav from "@/components/chat/FormNav";
import MainNav from "@/components/chat/MainNav";
import { QuestionTypeChatComponentsMixin } from "@/mixins/question-type-chat-components";
import { SurveyMetaMixin } from "@/mixins/survey-meta";
import { LocaleSwitchingMixin } from "@/mixins/locale-switching";
import { SurveyEndMixin } from "@/mixins/survey-end";
import { ScrollToTopMixin } from "@/mixins/scroll-to-top";
import { ErrorHandlerMixin } from "@/mixins/error-handler";
import { EnterSubmitMixin } from "@/mixins/keyboard-binding";
import { SurveyLayoutMixin } from "@/mixins/survey-layout";
import { setLang } from "@/utils/lang";
import { setRtl } from "@/utils/survey";
import _pickBy from "lodash.pickby";
import { LOCALE_MAP, CUSTOM_LANGUAGE_NAMES } from "@/constants/locale";
import LocaleCode from "locale-code";
import * as moment from "moment";

const getLanguageNativeName = LocaleCode.getLanguageNativeName;
export default {
  name: "ChatSurvey",
  mixins: [
    EnterSubmitMixin,
    QuestionTypeChatComponentsMixin,
    SurveyMetaMixin,
    LocaleSwitchingMixin,
    SurveyEndMixin,
    ScrollToTopMixin,
    ErrorHandlerMixin,
    SurveyLayoutMixin,
  ],
  components: {
    FormNav,
    MainNav,
  },
  data() {
    return {
      error: null,
      changes: {},
      showPolicy: false,
      showReward: false,
      forceHideReward: false,
      transitionType: "",
      invisible: true,
      urlError: false,
      allQuestions: [],
      isLoadingQuestion: false,
      questionType: "",
      isTextDisplay: { isTextDisplay: false, text: "" },
      questionsSAM: [],
      questionMatrix: [],
      questionMatrixIndex: 0,
      tmpQuestionMatrix: [],
      tmpChanges: {},
      questionIndexOfPage: 0,
      isSubmit: false,
      isShowQuestion: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading(this.code);
    },
    survey() {
      return this.$store.getters.survey(this.code);
    },
    page() {
      return this.$store.getters.page(this.code);
    },
    questions() {
      return this.$store.getters.questions(this.code);
    },
    response() {
      return this.$store.getters.response(this.code);
    },
    result() {
      return this.$store.getters.result(this.code);
    },
    resultWithQCode() {
      return this.$store.getters.resultWithQCode(this.code);
    },
    hiddenData() {
      return this.$store.getters.hiddenData(this.code);
    },
    currentState() {
      return this.$store.getters.currentState(this.code);
    },
    lang() {
      return this.$i18n.locale;
    },
    current() {
      return this.currentState?.pidx;
    },
    // mergedChanges() {
    //   return { ...this.result, ...this.changes };
    // },
    isFinished() {
      return !!this.response?.submitted_at;
    },
    isScreenOut() {
      return this.response?.is_screen_out;
    },
    isQuotaFull() {
      return this.response?.is_quota_full;
    },
    isBackable() {
      return this.currentState?.is_backable;
    },
    isScrollable() {
      return this.survey?.is_scrollable;
    },
    isAutoMoveNext() {
      return this.survey?.is_auto_move_next === undefined
        ? true
        : this.survey?.is_auto_move_next;
    },
    isPreviewBeforeSubmit() {
      return this.survey?.show_response_summary;
    },
    nextText() {
      if (
        this.isLastPage &&
        this.isLastPageNew &&
        this.translations.submit_chat_text
      ) {
        return this.translations.submit_chat_text;
      }
      return this.$t(
        `btn.${this.isLastPage && this.isLastPageNew ? "submit" : "next"}`
      );
    },
    isLastPageNew() {
      return this.questionIndexOfPage === this.questions.length - 1;
    },
    titleSurvey() {
      return this.translations.title;
    },
  },
  watch: {
    allQuestions(questions) {
      if (
        questions.length > 0 &&
        questions[questions.length - 1].question.type === "TD"
      ) {
        if (this.translations.got_it_chat_text) {
          this.isTextDisplay = {
            isTextDisplay: true,
            text: this.translations.got_it_chat_text,
          };
        } else {
          this.isTextDisplay = {
            isTextDisplay: true,
            text: this.$t("sns_translate.gotIt"),
          };
        }
      } else {
        this.isTextDisplay = { isTextDisplay: false, text: "" };
      }
    },
    isFinished() {
      if (this.endType === "completeurl") this.redirect("completed_url");
    },
    isLoadingQuestion(loading) {
      if (!loading) {
        this.scrollToBottom();
      }
    },
    questionsSAM() {
      this.scrollToBottom();
    },
  },
  methods: {
    ...mapActions([
      START_SURVEY,
      BACK,
      NEXT,
      SCREEN_OUT,
      LOGIN,
      QUOTA_FULL,
      SUBMIT_PREVIEW,
    ]),
    ...mapMutations([UPDATE_CHANGES]),
    decodeHtml(html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    async init() {
      const loadingComponent = this.$buefy.loading.open();
      const { lang } = this.$route.query;
      this.$i18n.locale = lang;
      setLang(lang);
      if (["ar", "fa", "ur"].includes(lang)) setRtl(true);
      this.error = null;
      try {
        const {
          params: { code },
          query,
        } = this.$route;
        await this[START_SURVEY]({ code, ...query });
        this.allQuestions.push({
          question: this.page.questions[this.questionIndexOfPage],
          answer: "",
        });
        this.questionType = this.page.questions[this.questionIndexOfPage].type;
        this.showPolicy = this.survey.show_policy;
        this.isShowQuestion = true;
      } catch (err) {
        this.errorHandler(err);
      } finally {
        loadingComponent.close();
      }
    },

    async moveNext(force = false) {
      if (
        (this.questions.length >= 2 ||
          this.isLastPage ||
          !this.isAutoMoveNext) &&
        !force
      )
        return;
      await this.onSubmit();
    },
    async isEnterActionSelect() {
      const kbEvent = new KeyboardEvent("keypress", {
        cancelable: true,
        key: "Enter",
        which: 13,
      });
      window.dispatchEvent(kbEvent);
    },
    async onSubmit() {
      const isValid = await this.$refs.surveySubmitObserver.validate();
      if (!isValid || this.loading) {
        this.scrollToError();
        return;
      }
      this.isShowQuestion = false;
      this.isLoadingQuestion = true;
      this.error = null;
      this.transitionType = "next-page";
      try {
        if (
          this.questions.length &&
          this.questions.find((i) => i.type === "C")
        ) {
          await this.login();
        } else {
          await this.handleNonLoginCase();
        }
      } catch (err) {
        this.errorHandler(err);
      }
    },
    scrollToError() {
      const el =
        screen.width > 1023
          ? document.querySelector(
              ".help.is-danger,.has-text-danger,.has-error"
            )
          : document.querySelector(
              ".help.is-danger,.label.has-text-danger,.has-error"
            );
      if (el) {
        window.setTimeout(function() {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 200);
      }
    },
    async login() {
      await this[LOGIN]({
        code: this.code,
        data: {
          response_id: this.response._id,
          username: this.changes.username,
        },
      });
    },
    async handleNonLoginCase() {
      const changesData = await this.filterChangesData();
      await this.mapQuestionAndAnswerForChatBox(changesData);
      const respondent_id = await this.handleSubmission(changesData);
      if (this.isPreviewBeforeSubmit && respondent_id && !this.page) {
        await this[SUBMIT_PREVIEW]({
          code: this.code,
          data: { response_id: respondent_id },
        });
      } else {
        await this.addNewQuestionInChatBox(changesData);
        await this.updateChanges();
      }
    },
    filterChangesData() {
      return _pickBy(this.changes, (index, key) => {
        return this.questions
          .map((question) => question.qid)
          .includes(Number.parseInt(key.split("_")?.[0]));
      });
    },
    handleNoChanges(changesArray, questionIndexShowHistory) {
      if (
        changesArray.length === 0 &&
        questionIndexShowHistory >= 0 &&
        this.allQuestions[questionIndexShowHistory].question.type !== "TD"
      ) {
        this.allQuestions[questionIndexShowHistory].answer = {
          [this.allQuestions[questionIndexShowHistory].question.qid]: this.$t(
            "sns_translate.noAnswer"
          ),
        };
      }
    },
    handleMIType(answerCurrentQuestion, questionAnswerIndexMT) {
      if (["MI"].includes(this.questions[this.questionIndexOfPage].type)) {
        if (answerCurrentQuestion[1]) {
          this.tmpChanges[`${this.allQuestions[questionAnswerIndexMT].qid}_`] =
            answerCurrentQuestion[1];
        } else {
          this.tmpChanges[`${this.allQuestions[questionAnswerIndexMT].qid}_`] =
            "";
        }
      }
    },
    handleSAMType(answerCurrentQuestion, questionIndexShowHistory) {
      if (["SAM"].includes(this.questions[this.questionIndexOfPage].type)) {
        const answerMatrix = this.questionsSAM[0].answers.find(
          (answer) => answer.code === answerCurrentQuestion[1]
        );

        if (answerMatrix) {
          const answerText = answerMatrix.answer[this.lang];
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]: answerText,
          };
          this.tmpChanges[
            `${this.allQuestions[questionIndexShowHistory].question.qid}_`
          ] = answerCurrentQuestion[1];
        } else {
          this.allQuestions[questionIndexShowHistory].answer = {
            [this.allQuestions[questionIndexShowHistory].question.qid]: this.$t(
              "sns_translate.noAnswer"
            ),
          };
          this.tmpChanges[
            `${this.allQuestions[questionIndexShowHistory].question.qid}_`
          ] = "";
        }
      }
    },
    handleMAMType(answerCurrentQuestion, questionIndexShowHistory) {

      if (["MAM"].includes(this.questions[this.questionIndexOfPage].type)) {
        let answerText = "<ul class='ma-answer'>";
        if (answerCurrentQuestion.length > 0) {
          answerCurrentQuestion.forEach((answer) => {
            answerText += "<li>";
            if (answer[1]) {
              const answerCode = answer[0].split("__")[1];
              const answerMatrix = this.questionsSAM[0].answers.find(
                (answer) => answer.code === answerCode
              );

              answerText += `${answerMatrix.answer[this.lang]}`;
              this.tmpChanges[answer[0]] = "1";
            }
            answerText += "</li>";
          });
        } else {
          answerText += "<li>";
          answerText += this.$t("sns_translate.noAnswer");
          answerText += "</li>";
        }
        answerText += "</ul>";
        this.allQuestions[questionIndexShowHistory].answer = {
          [this.allQuestions[questionIndexShowHistory].question
            .qid]: answerText,
        };
      }
    },
    handleSAType(answerCurrentQuestion, questionIndexShowHistory) {

      if (
        ["SA", "DL", "S"].includes(
          this.allQuestions[questionIndexShowHistory].question.type
        )
      ) {
        if (answerCurrentQuestion && answerCurrentQuestion[1]) {
          const currentQuestion = this.questions.find(
            (question) => question.qid === +answerCurrentQuestion[0]
          );
          const answerQuestion = currentQuestion.answers.find(
            (answer) => answer.code === answerCurrentQuestion[1]
          );
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]: answerQuestion.answer[this.lang],
          };
        } else {
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]: this.$t("sns_translate.noAnswer"),
          };
        }
      }
    },
    handleRType(changesArray, questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "R") {
        changesArray.sort((a, b) => a[1] - b[1]);
        // const sortedObj = Object.fromEntries(changesArray);
        let answerText = "<ol class='ma-answer'>";
        let qid = "";
        changesArray.forEach((change) => {
          if (change[1]) {
            answerText += "<li>";
            const answerQ = change[0].split("_");
            qid = answerQ[0];
            const subQuestionCode = answerQ[1];
            const subQuestion = this.allQuestions[
              questionIndexShowHistory
            ].question.sub_questions.find(
              (sub) => sub.code === subQuestionCode
            );

            answerText += `${subQuestion.question[this.lang]}`;
            answerText += "</li>";
          }
        });
        answerText += "</ol>";
        this.allQuestions[questionIndexShowHistory].answer = {
          [qid]: answerText,
        };
      }
    },
    handleMAType(changesArray, questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "MA") {
        let answerText = "<ul class='ma-answer'>";
        let qid = "";
        changesArray.forEach((change) => {
          if (change[1]) {
            answerText += "<li>";
            const answerQ = change[0].split("__");
            qid = answerQ[0];
            const answerCode = answerQ[1];
            const answerQuestion = this.allQuestions[
              questionIndexShowHistory
            ].question.answers.find((answer) => answer.code === answerCode);

            answerText += `${answerQuestion.answer[this.lang]}`;
            answerText += "</li>";
          }
        });
        answerText += "</ul>";
        this.allQuestions[questionIndexShowHistory].answer = {
          [qid]: answerText,
        };
      }
    },
    handleLLType(answerCurrentQuestion, questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "LL") {
        let answerText = "<ul class='ma-answer'>";
        if (answerCurrentQuestion[1].length > 0) {
          answerCurrentQuestion[1].forEach((c) => {
            answerText += "<li>";
            answerText += `${c.name}`;
            answerText += "</li>";
          });
        } else {
          answerText += "<li>";
          answerText += this.$t("sns_translate.noAnswer");
          answerText += "</li>";
        }

        answerText += "</ul>";
        this.allQuestions[questionIndexShowHistory].answer = {
          [answerCurrentQuestion[0]]: answerText,
        };
      }
    },
    handleSLType(answerCurrentQuestion, questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "SL") {
        setLang(answerCurrentQuestion[1]);
        this.allQuestions[questionIndexShowHistory].answer = {
          [answerCurrentQuestion[0]]: this.getLanguageNativeName(
            answerCurrentQuestion[1]
          ),
        };
      }
    },
    handleTDType(questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "TD") {
        this.allQuestions[questionIndexShowHistory].answer = {
          [this.allQuestions[questionIndexShowHistory].question.qid]: this
            .isTextDisplay.text,
        };
      }
    },
    handleDTType(answerCurrentQuestion, questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "DT") {
        this.allQuestions[questionIndexShowHistory].answer = {
          [answerCurrentQuestion[0]]: moment(answerCurrentQuestion[1]).format(
            "yyyy-MM-DD HH:mm"
          ),
        };
      }
    },
    handleFUType(answerCurrentQuestion, questionIndexShowHistory) {
      if (this.allQuestions[questionIndexShowHistory].question.type === "FU") {
        if (answerCurrentQuestion[1].length > 0) {
          let answerText = "<ul class='ma-answer'>";
          let countFile = 1;
          answerCurrentQuestion[1].forEach((c) => {
            if (c.match(/\.(jpeg|jpg|gif|png)$/) != null) {
              answerText += "<li>";
              answerText += `<img src="${c}" alt="image" style="max-width: 200px;  max-height: 200px;">`;
              answerText += "</li>";
            } else if (c.match(/\.(mp4|webm|ogg)$/) != null) {
              answerText += "<li>";
              answerText += `<video width="320" height="240" controls>
                      <source src="${c}" type="video/mp4">
                      <source src="${c}" type="video/ogg">
                      <source src="${c}" type="video/webm">
                      Your browser does not support the video tag.
                    </video>`;
              answerText += "</li>";
            } else {
              answerText += "<li>";
              answerText += `<a href="${c}" target="_blank">File ${countFile}</a>`;
              answerText += "</li>";
              countFile++;
            }
          });
          answerText += "</ul>";
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]: answerText,
          };
        } else {
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]: this.$t("sns_translate.noAnswer"),
          };
        }
      }
    },
    handleOtherType(answerCurrentQuestion, questionIndexShowHistory) {
      // check if question type is not in the list
      if (
        ![
          "SA",
          "DL",
          "S",
          "R",
          "MA",
          "LL",
          "SL",
          "TD",
          "DT",
          "FU",
          "SAM",
          "MAM",
        ].includes(this.allQuestions[questionIndexShowHistory].question.type)
      ) {

        if (
          this.allQuestions[questionIndexShowHistory].question.options?.suffix
        ) {
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]:
              answerCurrentQuestion[1] +
              " " +
              this.allQuestions[questionIndexShowHistory].question.options
                .suffix,
          };
        } else {
          this.allQuestions[questionIndexShowHistory].answer = {
            [answerCurrentQuestion[0]]: answerCurrentQuestion[1],
          };
        }
      }
    },
    mapQuestionAndAnswerForChatBox(changesData) {

      const currentQuestionType = this.questions[this.questionIndexOfPage].type;
      const questionIndexShowHistory = this.allQuestions.findIndex(
        (question) => {
          if (["SAM", "MI"].includes(currentQuestionType)) {
            return (
              this.questions[this.questionIndexOfPage].qid +
                "_" +
                this.questionMatrixIndex ===
              question.qid
            );
          } else if (currentQuestionType === "MAM") {
            const keysOfChangesData = Object.keys(changesData);
            return (
              keysOfChangesData[keysOfChangesData.length - 1].split("__")[0] ===
              question.question.qid
            );
          } else {
            return (
              question.question.qid ===
              this.questions[this.questionIndexOfPage].qid
            );
          }
        }
      );
      
      let currentQuestionQid, answerCurrentQuestion;
      const changesArray = Object.entries(changesData);
      if (["SAM", "MI"].includes(currentQuestionType)) {
        currentQuestionQid = this.allQuestions[questionIndexShowHistory].qid;
        answerCurrentQuestion = changesArray.find(
          (change) => change[0] === currentQuestionQid
        );
      } else if (currentQuestionType === "MAM") {
        currentQuestionQid = this.questionMatrix[this.questionMatrixIndex - 1]
          .question.qid;
        answerCurrentQuestion = changesArray.filter(
          (change) =>
            change[0].split("__")[0] === currentQuestionQid && change[1]
        );
      } else {
        currentQuestionQid = this.questions[this.questionIndexOfPage].qid;
        answerCurrentQuestion = changesArray.find(
          (change) => Number.parseInt(change[0]) === currentQuestionQid
        );
      }
      
      if (!answerCurrentQuestion) {
        answerCurrentQuestion = [];
      }

      this.handleNoChanges(changesArray, questionIndexShowHistory);
      this.handleMIType(answerCurrentQuestion, questionIndexShowHistory);
      this.handleSAMType(answerCurrentQuestion, questionIndexShowHistory);
      this.handleMAMType(answerCurrentQuestion, questionIndexShowHistory);

      if (questionIndexShowHistory >= 0) {
        this.handleSAType(answerCurrentQuestion, questionIndexShowHistory);
        this.handleRType(changesArray, questionIndexShowHistory);
        this.handleMAType(changesArray, questionIndexShowHistory);
        this.handleLLType(answerCurrentQuestion, questionIndexShowHistory);
        this.handleSLType(answerCurrentQuestion, questionIndexShowHistory);
        this.handleTDType(questionIndexShowHistory);
        this.handleDTType(answerCurrentQuestion, questionIndexShowHistory);
        this.handleFUType(answerCurrentQuestion, questionIndexShowHistory);
        this.handleOtherType(answerCurrentQuestion, questionIndexShowHistory);
      }
    },
    async handleSubmission(changesData) {
      if (
        this.questionIndexOfPage === this.questions.length - 1 &&
        (!["SAM", "MAM", "MI"].includes(
          this.questions[this.questionIndexOfPage].type
        ) ||
          (["SAM", "MAM", "MI"].includes(
            this.questions[this.questionIndexOfPage].type
          ) &&
            this.questionMatrixIndex === this.questionMatrix.length))
      ) {
        this.isSubmit = true;
        await this[NEXT]({
          code: this.code,
          data: {
            response_id: this.response._id,
            result: { ...changesData, ...this.tmpChanges },
          },
        });

        this.questionsSAM = [];
        this.questionMatrix = [];
        this.questionMatrixIndex = 0;
        this.tmpQuestionMatrix = [];
        this.tmpChanges = {};
        this.questionIndexOfPage = 0;
        return this.response._id;
      } else {
        this.isSubmit = false;
        if (
          !["SAM", "MAM", "MI"].includes(
            this.questions[this.questionIndexOfPage].type
          ) ||
          this.questionMatrixIndex === this.questionMatrix.length
        ) {
          this.questionIndexOfPage += 1;
          this.questionMatrixIndex = 0;
          this.questionMatrix = [];
          this.tmpQuestionMatrix = [];
        }
      }
    },
    handleAddNewQuestionSAMType() {
      if (this.questions[this.questionIndexOfPage] && this.questions[this.questionIndexOfPage].type === "SAM") {
        if (this.questionMatrixIndex === 0) {
          const qId = this.questions[this.questionIndexOfPage].qid;
          const tmpQ = this.questions[this.questionIndexOfPage].sub_questions;
          const tmpA = this.questions[this.questionIndexOfPage].answers;
          this.allQuestions.push({
            question: {
              ...this.questions[this.questionIndexOfPage],
              type: "TD",
            },
            answer: "",
          });
          tmpQ.forEach((q) => {
            this.questionMatrix.push({
              question: { ...q, qid: `${qId}_${q.code}` },
              answer: "",
              qid: `${qId}_${q.code}`,
            });
            const a = [
              {
                ...q,
                type: "SA",
                answers: tmpA,
                qid: `${qId}_${q.code}`,
                options: this.questions[this.questionIndexOfPage].options,
              },
            ];
            this.tmpQuestionMatrix.push(a);
          });
          this.allQuestions.push(this.questionMatrix[this.questionMatrixIndex]);
          this.questionsSAM = this.tmpQuestionMatrix[this.questionMatrixIndex];
          this.questionMatrixIndex++;
          this.isShowQuestion = true;
        } else {
          if (this.questionMatrixIndex < this.questionMatrix.length) {
            this.allQuestions.push(
              this.questionMatrix[this.questionMatrixIndex]
            );
            this.questionsSAM = this.tmpQuestionMatrix[
              this.questionMatrixIndex
            ];
            this.questionMatrixIndex++;
            this.isShowQuestion = true;
          }
        }
        this.isLoadingQuestion = false;
      }
    },
    handleAddNewQuestionMAMType() {
      if (this.questions[this.questionIndexOfPage].type === "MAM") {
        if (this.questionMatrixIndex === 0) {
          const qId = this.questions[this.questionIndexOfPage].qid;
          const tmpQ = this.questions[this.questionIndexOfPage].sub_questions;
          const tmpA = this.questions[this.questionIndexOfPage].answers;
          this.allQuestions.push({
            question: {
              ...this.questions[this.questionIndexOfPage],
              type: "TD",
            },
            answer: "",
          });
          tmpQ.forEach((q, i) => {
            this.questionMatrix.push({
              question: { ...q, qid: `${qId}_${q.code}` },
              answer: "",
              qid: `${qId}_${q.code}__${i + 1}`,
            });
            const a = [
              {
                ...q,
                type: "MA",
                answers: tmpA,
                qid: `${qId}_${q.code}`,
                options: this.questions[this.questionIndexOfPage].options,
              },
            ];
            this.tmpQuestionMatrix.push(a);
          });
          this.allQuestions.push(this.questionMatrix[this.questionMatrixIndex]);
          this.questionsSAM = this.tmpQuestionMatrix[this.questionMatrixIndex];
          this.questionMatrixIndex++;
          this.isShowQuestion = true;
          this.isLoadingQuestion = false;
        } else {
          if (this.questionMatrixIndex < this.questionMatrix.length) {
            this.allQuestions.push(
              this.questionMatrix[this.questionMatrixIndex]
            );
            this.questionsSAM = this.tmpQuestionMatrix[
              this.questionMatrixIndex
            ];
            this.questionMatrixIndex++;
            this.isShowQuestion = true;
          }
          this.isLoadingQuestion = false;
        }
      }
    },
    handleAddNewQuestionMIType() {
      if (this.questions[this.questionIndexOfPage].type === "MI") {
        if (this.questionMatrixIndex === 0) {
          const qId = this.questions[this.questionIndexOfPage].qid;
          const tmpQ = this.questions[this.questionIndexOfPage].sub_questions;
          const tmpA = this.questions[this.questionIndexOfPage].answers;
          const type = this.questions[this.questionIndexOfPage].options.type;
          this.allQuestions.push({
            question: {
              ...this.questions[this.questionIndexOfPage],
              type: "TD",
            },
            answer: "",
          });
          tmpQ.forEach((q) => {
            this.questionMatrix.push({
              question: { ...q, qid: `${qId}_${q.code}` },
              answer: "",
              qid: `${qId}_${q.code}`,
            });
            const a = [
              {
                ...q,
                type: type == "text" ? "FA" : "NI",
                answers: tmpA,
                qid: `${qId}_${q.code}`,
                options: this.questions[this.questionIndexOfPage].options,
              },
            ];
            this.tmpQuestionMatrix.push(a);
          });
          this.allQuestions.push(this.questionMatrix[this.questionMatrixIndex]);
          this.questionsSAM = this.tmpQuestionMatrix[this.questionMatrixIndex];
          this.questionMatrixIndex++;
          this.isShowQuestion = true;
          // this.allQuestions.push(this.questionMatrix[this.questionMatrixIndex]);
          // this.questionsSAM = this.tmpQuestionMatrix[this.questionMatrixIndex];
          // this.questionMatrixIndex++;
        } else {
          if (this.questionMatrixIndex < this.questionMatrix.length) {
            this.allQuestions.push(
              this.questionMatrix[this.questionMatrixIndex]
            );
            this.questionsSAM = this.tmpQuestionMatrix[
              this.questionMatrixIndex
            ];
            this.questionMatrixIndex++;
            this.isShowQuestion = true;
          }
        }
        this.isLoadingQuestion = false;
      }
    },
    handleAddNewQuestionOtherTypes() {
      if (
        !["SAM", "MAM", "MI"].includes(
          this.questions[this.questionIndexOfPage].type
        )
      ) {
        this.allQuestions.push({
          question: this.questions[this.questionIndexOfPage],
          answer: "",
        });

        this.questionType = this.questions[this.questionIndexOfPage].type;
        this.isLoadingQuestion = false;
        this.isShowQuestion = true;
      }
    },
    async addNewQuestionInChatBox() {
      setTimeout(() => {
          this.handleAddNewQuestionSAMType();
          this.handleAddNewQuestionMAMType();
          this.handleAddNewQuestionMIType();
          this.handleAddNewQuestionOtherTypes();
      }, 500);
    },
    updateChanges() {
      let changes = this.currentState?.changes || {};
      for (const [key, value] of Object.entries(changes)) {
        const exist = this.questions.find((question) => {
          return question.qid.toString() === key.split("_")?.[0].toLowerCase();
        });

        changes[key] = exist ? value : null;
      }
      this.changes = changes;
    },
    async onBack() {
      this.error = null;
      this.$refs.surveySubmitObserver.reset();
      this.transitionType = "back-page";
      try {
        await this[BACK]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes },
        });
        this.changes = this.currentState?.changes || {};
        if (this.page && !this.questions.length) await this.onBack();
      } catch (err) {
        this.errorHandler(err);
      }
    },
    async onScreenOut() {
      try {
        await this[SCREEN_OUT]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes },
        });
        if (this.endType === "completeurl" && this.translations.screen_out_url)
          this.redirect("screen_out_url");
      } catch (err) {
        this.errorHandler(err);
      }
    },
    async onQuotaFull() {
      try {
        await this[QUOTA_FULL]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes },
        });
        if (this.endType === "completeurl" && this.translations.quota_full_url)
          this.redirect("quota_full_url");
      } catch (err) {
        this.errorHandler(err);
      }
    },
    onChange(data) {
      this.changes = { ...this.changes, ...data };
      // this.changes = {};
      this[UPDATE_CHANGES]({ code: this.code, changes: this.changes });
    },
    redirectUrl(urlType) {
      const regex = /\[\w+]/g;
      let url = this.translations?.[urlType];
      let m;
      while ((m = regex.exec(url)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match) => {
          const key = match.replace("[", "").replace("]", "");
          url = url.replace(`${match}`, this.hiddenData[key] ?? `${match}`);
        });
      }
      return url;
    },
    isValidUrl(url) {
      try {
        return Boolean(new URL(url));
      } catch (e) {
        return false;
      }
    },
    async redirect(urlType) {
      let url = this.redirectUrl(urlType);
      if (url) {
        const isValidUrl = this.isValidUrl(url);
        if (!isValidUrl) {
          this.urlError = true;
          return;
        }
      }
      const isExternalURL = new URL(url).origin !== location.origin;

      const vueRoute = this.$router.resolve(new URL(url).pathname);
      const routeName = vueRoute.route.name;
      if (isExternalURL || routeName !== "Survey") window.location.href = url;
      try {
        const {
          data: { data },
        } = await getSurveyFull(vueRoute.route.params.code);
        if (data?.is_active) window.location.href = url;
      } catch (err) {
        console.log(err);
      }
      this.showReward = false;
      return;
    },
    forceReloadIfNotRestorable() {
      window.onpageshow = (event) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    },
    scrollToBottom() {
      var chatHistory = document.getElementById("chats");
      const itv = setInterval(() => {
        const elmSurvey = document.getElementById("survey");
        if (elmSurvey) {
          chatHistory.scrollTo({
            top: chatHistory.scrollHeight + 500,
            behavior: "smooth",
          });
          clearInterval(itv);
        }
      }, 1000);
    },
    getLanguageNativeName(locale) {
      if (!LOCALE_MAP[locale]) return locale;
      return (
        CUSTOM_LANGUAGE_NAMES[LOCALE_MAP[locale]] ??
        getLanguageNativeName(LOCALE_MAP[locale])
      );
    },
    showInstruction(questionType) {
      if (["SA", "DL", "S", "SL"].includes(questionType)) {
        return this.$t(`sns_translate.selectOne`);
      } else if (questionType === "MA") {
        const question = this.questions[0];
        const options = question.options;
        if (options && options.mandatory) {
          if (options.min && options.max) {
            return this.$t(`multiple_answer.mandatory.between`, {
              min: options.min,
              max: options.max,
            });
          } else if (options.min) {
            return this.$t(`multiple_answer.mandatory.min`, {
              min: options.min,
            });
          } else if (options.max) {
            return this.$t(`multiple_answer.mandatory.max`, {
              max: options.max,
            });
          } else {
            return this.$t(`multiple_answer.mandatory.any`);
          }
        } else {
          return this.$t(`multiple_answer.mandatory.any`);
        }
      } else {
        return "";
      }
    },
  },
  async created() {
    this.forceReloadIfNotRestorable();
    await this.init();
  },
};
</script>
<style lang="scss">
.chat-container {
  display: flex;
  .mobile-progress {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &#survey-scroller {
    position: relative;
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
}
.chat-survey-name {
  display: flex;
  border-bottom: 1px solid #ccc;
  align-items: center;
  padding: 10px 0;
  font-weight: bold;
}

.live-chat {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;

  .columns:not(:last-child) {
    margin-bottom: 0;
    width: 100%;
    max-height: 33vh;
    overflow-y: auto;
  }

  .satisfaction .field.has-addons {
    flex-direction: column;
  }

  .ma-answer {
    padding: 0 10px;
    text-align: left;
  }

  .fade-in-text {
    animation: left-to-right-fade-in 0.3s ease-in;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

  @keyframes left-to-right-fade-in {
    0% {
      -webkit-mask-size: 0%;
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0)
      );
    }

    100% {
      -webkit-mask-size: 100%;
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0)
      );
    }
  }

  .autocomplete .dropdown-menu.is-opened-top {
    height: 100%;
  }
  .b-radio.radio.button,
  .b-checkbox.checkbox.button {
    display: flex;
    height: auto;
    white-space: break-spaces;
  }

  .keyboard {
    padding: 0 5px;
    background: white;
    color: #00bd9d;
    margin-right: 5px;
  }

  .space-content {
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 30px 0 20px;
    transition: all 0.5s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      bottom: 70%;
      border-bottom: 1px #ddd solid;
      width: 100%;
      z-index: 0;
    }

    &-type-question {
      text-align: center;
    }
  }

  .card {
    box-shadow: none;
  }

  .footer-form {
    padding-bottom: 1.5rem;
  }

  #survey {
    display: flex;
    align-items: center;
    flex-direction: column;

    .chat-content-question {
      overflow-y: scroll;
      z-index: 1;
    }

    .answer {
      span.option {
        width: 100%;
        text-align: left;
      }
    }

    .question {
      flex: 1 1 auto;
      padding-left: 0;
      padding-right: 0;

      .is-flex.is-vcentered {
        width: 100%;
      }
      .card-content {
        padding: 1.5rem 0;
      }
    }

    .form-submit {
      justify-content: flex-end;
      width: 100%;
      background: white;
    }
  }

  .chats {
    margin: 0;
    flex-grow: 1;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    ul {
      list-style: disc;
    }

    .question {
      display: flex;
      justify-content: flex-start;
      // max-width: 70%;
      align-items: flex-end;
      margin-top: 10px;
      gap: 5px;

      .avatar {
        width: 35px;
        min-width: 35px;
        height: 35px;
        // background: #00bd9d;
        border-radius: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
      }

      &-item {
        // font-weight: 600;
        background: #ddd;
        transition: smooth-appear 1s ease forwards;
        margin: 0 0.2em;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border-bottom-left-radius: 0;
        flex: 0 1 auto;
        min-height: 35px;
      }
      .loading-question {
        background: #fff;
        padding-left: 0;
      }

      .img-avatar {
        width: 35px;
        height: 35px;
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.5s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
    }

    .answer {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;

      &-item {
        color: #fff;
        // font-weight: 600;
        background: #00bd9d;
        text-align: right;
        margin: 0 0.2em;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border-bottom-right-radius: 0;
        min-height: 35px;
        flex: 0 1 auto;

        .ma-answer {
          a {
            color: $white;
          }
        }
      }
    }

    .loader {
      width: 60px;
      aspect-ratio: 2;
      --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
      background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
      background-size: calc(100% / 3) 50%;
      animation: l3 1s infinite linear;
    }

    @keyframes l3 {
      20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
      }

      40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
      }

      60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
      }

      80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .live-chat{
      #survey {

      .question {
        max-height: 33vh;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .live-chat{
    height: 90vh;
      #survey {

      .question {
        flex: 1 1 auto;
        padding-left: 0;
        padding-right: 0;
          .card-content {
            padding: 0 0 !important;
          }
      }
    }
  }
}


@media screen and (max-width: 678px) {
  .live-chat {
     height: 84vh;
  }
}
@media screen and (max-width: 678px) and (min-height: 768px) {
  .live-chat {
     height: 90vh;
  }
}
</style>

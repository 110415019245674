<template>
  <div v-if="isLastPage" class="columns container form-nav footer-nav">
    <b-button
      :disabled="!nextable"
      :loading="loading"
      native-type="submit"
      type="is-primary"
      class="submit-button"
    >
      {{ nextText }}
    </b-button>
  </div>
  <div
    v-else-if="isTextDisplay.isTextDisplay"
    class="form-nav"
    style="text-align: center"
  >
    <b-button
      :disabled="!nextable"
      :loading="loading"
      native-type="submit"
      type="is-primary"
      class="submit-button"
      style="width: auto"
    >
      {{ isTextDisplay.text }}
    </b-button>
  </div>
  <div
    class="columns container is-flex is-align-content-center is-justify-content-space-between form-nav has-text-centered footer-nav"
    v-else
  >
    <div class="logo is-flex is-align-items-center">
      <span class="powered-by" v-html="$t('powered_by')"></span>
    </div>
    <div class="nav is-flex is-align-content-center">
      <!-- <div
        class="is-hidden-touch survey-progress is-flex is-flex-direction-column is-justify-content-flex-end mr-4"
      >
        <span class="is-align-self-flex-start is-size-7">{{
          $t("progress", { percent: this.percentage })
        }}</span>
        <b-progress
          :max="max"
          :value="current"
          size="is-tiny"
          type="is-dark-green"
        >
        </b-progress>
      </div> -->
      <div class="ml-1">
        <b-button
          :disabled="!nextable"
          :loading="loading"
          native-type="submit"
          outlined
          type="is-primary"
          class="submit-button"
        >
          <!-- <template v-if="isTextDisplay">Got it!</template> -->
          <b-icon icon="send"></b-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormNav",
  props: {
    loading: {
      type: Boolean,
    },
    backable: {
      type: Boolean,
    },
    nextable: {
      type: Boolean,
    },
    hideBackBtn: {
      type: Boolean,
    },
    backText: {
      type: String,
      default: "Back",
    },
    nextText: {
      type: String,
      default: "Next",
    },
    current: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    isLastPage: {
      type: Boolean,
      default: false,
    },
    isTextDisplay: {
      type: Object,
      default: null,
    },
  },
  computed: {
    percentage() {
      const percentage = (this.current / this.max) * 100;
      return percentage.toFixed(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-nav {
  padding: 1rem 0 1.5rem;
  z-index: 99;
}
.submit-button {
  width: 100%;
  min-width: 40px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  span {
    width: 100%;
  }
}
.footer-nav {
  left: 0 !important;
  right: 0 !important;
  position: relative !important;
  width: 100%;
  margin: 0 auto 1.5rem !important;
  align-items: center;
}
</style>
